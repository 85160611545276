import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet/es/Helmet"

import { SetBodyClass } from "../../util/body-attributes-context"
import Heading from "../heading/heading"
import "./page-header.sass"

const PageHeader = ({
  levelThreeTitle,
  levelTwoTitle,
  container,
  levelOneTitle,
  semanticHierarchy,
  ...props
}) => {
  let containerClass
  switch (container) {
    case "default":
      containerClass = "_fp-global-container"
      break
    case "secondary":
      containerClass = "_fp-global-container-secondary"
      break
    case "text":
      containerClass = "_fp-global-container _fp-global-text-container"
      break
    case "tertiary":
      containerClass = "_fp-global-container-tertiary"
      break
    default:
      containerClass = "_fp-global-container"
      break
  }

  // Exceptions
  if (props.title) {
    throw new Error('"title" prop is deprecated')
  }
  if (props.subtitle) {
    throw new Error('"subtitle" prop is deprecated')
  }
  if (props.titleTranslatedString) {
    throw new Error('"titleTranslatedString" prop is deprecated')
  }
  if (props.subtitleTranslatedString) {
    throw new Error('"subtitleTranslatedString" prop is deprecated')
  }
  if (props.pageTranslatedString) {
    throw new Error('"pageTranslatedString" prop is deprecated')
  }
  //

  return (
    <>
      <SetBodyClass
        className={classNames("_fp-has-page-header", {
          "_fp-has-page-header--desktop-multiline":
            levelTwoTitle && levelTwoTitle !== levelThreeTitle,
          "_fp-has-page-header--mobile-multiline":
            levelOneTitle && levelOneTitle !== levelTwoTitle,
        })}
      />
      <div className="_fp-page-header">
        <div className={containerClass}>
          <div className="_fp-grid">
            {/*Desktop*/}
            <div className="_fp-col-12 _fp-col-0sp _fp-col-0p _fp-col-0st _fp-col-0t">
              {levelTwoTitle && levelTwoTitle !== levelThreeTitle && (
                <p className={"_fp-text _fp-page-header__subtitle"}>
                  {levelTwoTitle}
                </p>
              )}
              <Heading
                type={semanticHierarchy}
                className={"_fp-heading-1 _fp-page-header__title"}
              >
                {levelThreeTitle}
              </Heading>
            </div>

            {/*Mobile*/}
            <div className="_fp-col-0 _fp-col-12sp _fp-col-12p _fp-col-12st _fp-col-12t">
              {levelOneTitle && levelOneTitle !== levelTwoTitle && (
                <p className={"_fp-text _fp-page-header__subtitle"}>
                  {levelOneTitle}
                </p>
              )}
              <div className={"_fp-heading-1 _fp-page-header__title"}>
                {levelTwoTitle}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="_fp-page-header-spacer" />
    </>
  )
}

PageHeader.propTypes = {
  container: PropTypes.oneOf(["default", "secondary", "text", "tertiary"]),
  semanticHierarchy: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "div",
  ]),
  levelOneTitle: PropTypes.string,
  levelTwoTitle: PropTypes.string.isRequired,
  levelThreeTitle: PropTypes.string.isRequired,
}

PageHeader.defaultProps = {
  container: "default",
  semanticHierarchy: "h1",
}

export default PageHeader
